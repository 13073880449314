<template>
	<!-- 畜牧商家列表 -->
	<div class="bullMarket-merchant el-content">
		<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
			{title:'ID',dataIndex:'id'},
			{title:'商家信息',dataIndex:'shop',slots:{customRender:'shop'}},
			{title:'电子账户',dataIndex:'account',slots:{customRender:'account'}},
			{title:'钱包',dataIndex:'money',slots:{customRender:'money'}},
			{title:'禁用状态',dataIndex:'status',slots:{customRender:'status'}},
			{title:'审核状态',dataIndex:'check',slots:{customRender:'check'}},
			{title:'发布权限',dataIndex:'auth',slots:{customRender:'auth'}},
			{title:'更新时间',dataIndex:'create_time'},
		]" >
			<template #shop="{record}">
				<div style="display: flex;align-items: center;" v-if="record.account">
					 <a-avatar :src="record.account.avatar_url"/>
					 <div style="margin-left: 4px;">
						<div><span>{{record.account.nickname}}</span></div>
						<div><span class="f12">user_id:</span>{{record.account.user_id}}</div>
					 </div>
				</div>
			</template>
			<template #account="{record}">
				<a-tag color="#00CC66" v-if="record.is_bind_bank == 1">已开通</a-tag>
				<a-tag color="#FF0066" v-if="record.is_bind_bank == 0">未开通</a-tag>
			</template>
			<template #money="{record}">
				<div><span class="f12">余额：</span><span style="color: #07be16;">{{record.ceb.bank}}</span> 元</div>
				<div><span class="f12">冻结余额：</span><span style="color: red;">{{record.ceb.frozen}}</span> 元</div>
			</template>
			<template #status="{record}">
				<a-tag color="#FF0066" v-if="record.is_disable == 1">禁用</a-tag>
				<a-tag color="#00CC66" v-if="record.is_disable == 0">正常</a-tag>
			</template>
			<template #check="{record}">
				<a-tag color="info" v-if="record.audit == 0">未审核</a-tag>
				<a-tag color="#00CC66" v-if="record.audit == 1">审核成功</a-tag>
				<a-tag color="#FF0066" v-if="record.audit == 2">审核拒绝</a-tag>
			</template>
			<template #auth="{record}">
				<a-tag color="#00CC66" v-if="record.is_public_animal == 1">允许发布</a-tag>
				<a-tag color="#FF0066" v-if="record.is_public_animal == 0">不允许发布</a-tag>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange="(p,e)=>{getBullMerchant(info.page,e)}"
				@change="(e)=>{getBullMerchant(e,info.limit)}"
			/>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import bullModel from '@/api/addons/bull'
export default{  
	setup(){
		const state = reactive({
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			}
		})
		getBullMerchant(1,state.info.limit)
		function getBullMerchant(page,limit){
			bullModel.getBullMerchant(page,limit,res=>state.info = {limit,...res})
		}
		return{
			...toRefs(state),
			getBullMerchant
		}
	}
}
</script>

<style>
</style>
